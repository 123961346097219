<template>
  <div 
    :class="{ 'pa-4': breakpoint('xs')}"
    class="flashmobs pb-8"
  >
    <div
      class="toolbar white rounded-t pa-4 d-flex align-center"
      :style="{ top: breakpoint('md') ? '64px' : '56px' }"
    >
      <v-text-field
        v-model="controller.search.query"
        :loading="controller.search.loading"
        :disabled="controller.search.disabled"
        :prepend-icon="icons.search"
        :placeholder="controller.search.placeholder"
        solo
        flat
        dense
        clearable
        hide-details
        class="search-field field"
      />
      <!-- <div class="filters mx-2">
        <span class="text-body-2 grey--text">
          Filtrar por:
        </span>
        <v-chip
          v-for="(filter, f) in controller.filters.options"
          :key="'filter-'+f"
          :color="filter.active ? 'primary' : 'grey lighten-2'"
          small
          class="filter mx-2"
          @click="filter.active=!filter.active;"
        >
          {{ filter.text }}
        </v-chip>
      </div> -->
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :loading="controller.list.loading"
            :disabled="controller.list.disabled||controller.list.loading"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click="get(null)"
          >
            <v-icon>
              {{ icons.refresh }}
            </v-icon>
          </v-btn>
        </template>
        Atualizar
      </v-tooltip>
      <v-btn
        depressed
        color="primary"
        class="ml-2"
        @click="select('new')"
      >
        <v-icon left>
          {{ icons.add }}
        </v-icon>
        Criar
      </v-btn>
    </div>
    <v-simple-table
      fixed-header
      class="list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th 
              v-for="(column, key) in header"
              :key="'header-'+key"
              :width="'width' in column ? column.width : 'auto'"
              :class="['text-'+('align' in column ? column.align : 'left')]"
            >
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <v-progress-linear
          :active="controller.list.loading"
          indeterminate
          absolute
          height="2"
        />
        <tbody>
          <tr
            v-for="item in list"
            :key="'flashmob-'+item.id"
            :class="{ 'faded': !item.active }"
            class="flashmob-item"
            @click="select(item.id)"
          >
            <td 
              v-for="(column, key) in header"
              :key="'flashmob-'+item.id+'-'+key"
              :title="item[key]"
              :class="[{ truncate: 'truncate' in column && column.truncate }, 'text-'+column.align]"
            >
              <span
                v-if="key=='title'"
              >
                {{ item[key] }}
                <v-tooltip 
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.hoload ? '🌟' : '' }}
                    </span>
                  </template>
                  Hoload
                </v-tooltip>
              </span>
              <span v-else>
                {{ item[key] }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <flashmob
      v-model="controller.update.toggle"
      :loading="controller.update.loading"
      :data="selected"
      :drivers="drivers"
      :driver-prototype="flasmobDriverPrototype"
      :user="user"
      @save="save"
      @invite="invite"
      @rsvp="rsvp"
      @payment="payment"
      @cancel="cancel"
      @message="toggleMessenger"
      @open-map="openMap"
      @open-driver="openDriver"
      @alert="toggleToast"
      @close="select(null)"
    />

    <messenger 
      :toggle="controller.messenger.toggle"
      :to="controller.messenger.to"
      :subject="controller.messenger.subject"
      :message="controller.messenger.message"
      :reference="controller.messenger.reference"
      :user="user.username"
      :token="user.auth.token"
      @toggle-messenger="toggleMessenger"
      ref="messenger" 
    />
  </div>
</template>

<style scoped>

  .list {
    position: relative;
  }

  .toolbar {
    position: sticky !important;
    z-index: 3;
  }
  .list .flashmob-item {
    cursor: pointer;
    will-change: opacity;
    transition: .15s opacity ease-out;
  }
  .list .flashmob-item.faded:not(:hover) {
    opacity: .5;
  }
  .truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-field {
    max-width: 50%;
    background: transparent;
  }
  .toolbar .search-field .v-input__slot {
    background: transparent;
  }
</style>

<script>
  import services from '@/services'
  import { mdiMagnify, mdiPlus, mdiBroadcast, mdiCheck, mdiRefresh } from '@mdi/js';
  import moment from 'moment';
  const md5 = require('md5');


  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'Flashmobs',
    metaInfo: {
      title: 'Flashmobs'
    },

    data: () => ({
      icons: {
        search: mdiMagnify,
        add: mdiPlus,
        check: mdiCheck,
        refresh: mdiRefresh
      },
      controller: {
        search: {
          query: null,
          loading: false,
          disabled: false,
          placeholder: 'Buscar Flashmob...',
          options: {
            type: [
              {
                text: 'Flashmob',
                value: 'FLS',
              },
              {
                text: 'Fila',
                value: 'FIL',
              },
              {
                text: 'Swarm',
                value: 'SWR',
              }
            ]
          }
        },
        filters: {
          options: {
            active: {
              value: true,
              text: 'Próximos',
              active: true
            }
          }
        },
        update: {
          toggle: false,
          errors: [],
          success: null,
          updated: false,
          loading: false,
          disabled: false,
        },
        list: {
          header: {
            'title': {
              title: 'Tipo | Nome',
              width: '30%',
              truncate: true,
              mobile: true,
            },
            'date': {
              title: 'Data',
              width: '20%',
            },
            'location': {
              title: 'Local',
              truncate: true,
            },
            'drivers': {
              title: 'Parceiros',
              width: '10%',
            },
            'program': {
              title: 'Status Fila',
              width: '10%',
              align: 'right',
              mobile: true,
            },
          },
          updated: false,
          loading: false,
        },
        messenger: {
          toggle: false,
          to: [],
          subject: null,
          message: null,
          reference: null
        },
      },
      dictionary: {
        id: 'id_flashmob',
        title: 'nm_flashmob',
        type: 'tipo_flashmob',
        hoload: 'hoload',
        active: 'bo_ativo',
        mobilizesAt: 'dt_mobilizacao',
        startsAt: 'dt_inicio',
        endsAt: 'dt_fim',
        demobilizesAt: 'dt_desmobilizacao',
        location: 'local',
        meeting_point: 'ponto_de_encontro',
        max: 'max_participantes',
        obs: 'obs',
        route: 'rota',
        reward: 'remuneracao',
        program_id: 'program_id',
        program: 'program',
        drivers: {
          key: 'parceiros',
          acceptedAt: 'dt_aceite',
          declinedAt: 'dt_declinio',
          invitedAt: 'dt_convite',
          uninvitedAt: 'dt_desconvite',
          partner_id: 'id_parceiro',
          id: 'id_motorista',
          fullname: 'nm_motorista',
          cpf: 'cpf_motorista',
          email: 'email_motorista',
          id_device: 'id_device',
          cod_device: 'cod_device',
          status_device: 'status_device',
          dt_status_device: 'dt_status_device',
          dt_impression_device: 'dt_impression_device',
          swarm: 'swarm',
          id_flashmob_driver: 'id_flashmob_parceiro'
        }
      }
    }),

    computed: {
      items: sync('flashmobs/items'),
      drivers: sync('drivers/data@items'),
      driverPrototype: sync('drivers/data@prototype'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      header () {
        const isMobile = this.breakpoint(null, 'xs');
        return _.pickBy(this.controller.list.header, column => {
          return !isMobile||(_.has(column, 'mobile')&&column.mobile);
        })
      },

      list () {
        const items = _.clone(this.items);
        const search = this.controller.search;
        let query = search.query;
        query = this.isValid(query) ? query.normalize("NFD").replace(/\p{Diacritic}/gu, "") : '';
        const fields = _.keys(this.controller.list.header);
        let list = this.searchEngine(items, query, true, fields);
        list = !_.isEmpty(list) ? list : this.searchEngine(items, query);
        list = _.orderBy(_.map(list, item => {
          const date = _.reduce(_.pick(item, ['mobilizesAt', 'startsAt']), (date, d) => {
            return date==null ? d!=null ? moment(d).format('DD/MM HH:mm') : null : date;
          }, null);
          const active = item.demobilizesAt==null||moment().isBefore(item.demobilizesAt);
          const confirmed = _.filter(_.clone(item.drivers), driver => {
            return driver.acceptedAt!=null&&(driver.declinedAt==null||moment(driver.acceptedAt).isAfter(driver.declinedAt))&&driver.uninvitedAt==null;
          });
          const invited = _.filter(_.clone(item.drivers), driver => {
            return driver.invitedAt!=null&&(driver.declinedAt==null||moment(driver.acceptedAt).isAfter(driver.declinedAt))&&driver.uninvitedAt==null;
          });
          const program_key = _.isNil(item.program) ? null : item.program.program_id+'-'+this.slugify(item.title);
          const program = _.isNil(item.program) ? null : _.reduce(invited, (status, driver) => {
            const swarm = _.split(driver.swarm, '^');
            const program = _.find(swarm, s => s.indexOf(program_key)>=0);
            status[_.isNil(program) ? 'pending' : program.indexOf('$$ERRO')>=0 ? 'error' : 'ok'] += 1;
            return status;
          }, { pending: 0, error: 0, ok: 0 });
          return {
            ...item,
            active,
            title: _.find(search.options.type, ['value', item.type]).text + ' | ' + item.title,
            date: date||'A definir',
            drivers: _.size(invited)>0 ? _.size(confirmed) + '/' + _.size(invited) : '–',
            program: _.isNil(program)? '–' : program.error>0 ? '🔴' : program.pending>0 ? '🟡' : '🟢'
          }
        }), ['mobilizesAt', 'startsAt', 'title', 'local'], ['desc', 'desc', 'asc', 'asc']);
        // const filters = this.filters;
        // console.log(filters);
        return list //_.size(filters)>0 ? _.filter(list, filters) : list;
      },

      filters () {
        const options = _.cloneDeep(this.controller.filters.options);
        return _.reduce(options, (active, filter, key) => {
          if (filter.active) active[key] = filter.value;
          return active;
        }, {})
      },

      selected () {
        const route = this.$route;
        return _.has(route.params, 'id') ? this.items[route.params.id] : null;
      },

      flasmobDriverPrototype () {
        const flashmobDriver = _.mapValues(_.clone(this.dictionary.drivers), f => null);
        return _.merge(_.clone(this.driverPrototype), flashmobDriver);
      }
    },

    watch: {
      $route: {
        immediate: true,
        handler ($route) {
          this.controller.update.toggle = !!this.selected||$route.params.id=='new';
          if (!!this.selected) this.get(this.selected.id, this.controller.update);
        }
      },
      'controller.update.toggle': {
        immediate: true,
        handler (toggle) {
          if (!toggle&&!!this.selected) this.select(null);
        }
      },
    },
    methods: {
      ...services,
      md5,

      select (id) {
        if (id!==null) {
          this.$router.push({ path: `/flashmobs/${id}` });
        }else{
          this.$router.push('/flashmobs');
        }
        this.$nextTick(() => {
          this.controller.update.toggle = !!id;
        })
      },

      save (fields) {
        const id = _.isNil(this.selected) ? null : this.selected.id;
        if (!!id) {
          this.update(id, fields);
        }else{
          this.create(fields);
        }
      },

      format (fields, outwards=false) {
        const dictionary = this.dictionary;
        return _.mapValues(_.mapKeys(fields, (field, f) => {
          return outwards ? _.isObject(dictionary[f]) ? dictionary[f].key : dictionary[f] : _.findKey(dictionary, d => _.isObject(d) ? d.key : d==f);
        }), (field, f) => {
          return _.isArray(field) ? _.map(field, item => {
            return !_.isObject(item) ? item : _.mapValues(_.mapKeys(item, (subfield, s) => {
              return outwards ? dictionary[f][s] : _.findKey(dictionary[f], d => d==s);
            }), (subfield, s) => {
              return s.indexOf(outwards ? 'dt_' : 'At')>=0&&subfield!=null ? this.formatDate(subfield, outwards) : subfield
            });
          }) : f.indexOf(outwards ? 'dt_' : 'At')>=0&&field!=null ? this.formatDate(field, outwards) : field
        });
      },

      formatDate (value, outwards) {
        if (moment(value).isValid()) {
          if (outwards) {
            value = moment(value).utc();
          }else{
            value = moment.utc(value).local();
          }
        }
        return value.format('YYYY-MM-DD HH:mm:ss');
      },

      get (id=null, controller=this.controller.list) {
        controller.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const filters = this.filters;
        const data = {
          username,
          token,
          // ...filters
        };

        console.log('flashmobs.get...', data);
        this.$api.COM
          .get('/flashmob' + (!!id ? '/'+id : ''), { params: data })
          .then(response => {
            console.log('flashmobs.get => ', response);
            const items = response.data;
            _.each(_.isArray(items) ? items : [items], item => {
              item = this.format(item);
              const cached = _.has(this.items, item.id) ? _.clone(this.items[item.id]) : {}
              this.$set(this.items, item.id, _.merge(cached, item));
            });
            controller.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Não foi possível carregar a lista de Flashmobs. Atualize a página, por favor.', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            controller.loading = false;
            // if (!!id) this.getDriverList({ status: 'ACT' }, this.controller.update);
          });
      },

      create (fields) {
        this.controller.update.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        fields = this.format(fields, true);
        const data = {
          username,
          token,
          fields
        };

        console.log('flashmobs.create...', data);

        this.$api.COM
          .post('/flashmob/', data)
          .then(response => {
            console.log('flashmobs.create => ', response);
            const id = response.data.id_flashmob;
            this.$set(this.items, id, this.format(response.data));

            this.$nextTick(() => {
              this.select(id);
            })

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      update (id, fields) {
        this.controller.update.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        fields = this.format(fields, true);
        const data = {
          username,
          token,
          fields
        };

        console.log('flashmobs.update...', data);

        this.$api.COM
          .put('/flashmob/'+id, data)
          .then(response => {
            console.log('flashmobs.update => ', response);
            this.$set(this.items, id, this.format(response.data));

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      invite (id, drivers, ticket_data) {
        this.controller.update.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
          drivers,
          ticket_data
        };

        console.log('flashmobs.invite...', data);

        this.$api.COM
          .patch('/flashmob/'+id+'/invite', data)
          .then(response => {
            console.log('flashmobs.invite => ', response);
            const flashmob = { ...this.items[id], ...this.format(response.data) };
            this.$set(this.items, id, flashmob);
            
            if (response.status==207) {
              this.toggleToast(
                true,
                'Não foi possível enviar convite para alguns parceiros.',
                5000,
                false
              );
            }

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      rsvp (id, partner_id) {
        this.controller.update.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
          id_parceiro: partner_id,
          accepted: true
        };

        console.log('flashmobs.rsvp...', data);

        this.$api.COM
          .patch('/flashmob/'+id+'/rsvp', data)
          .then(response => {
            console.log('flashmobs.rsvp => ', response);
            const flashmob = { ...this.items[id], ...this.format(response.data) };
            this.$set(this.items, id, flashmob);

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      payment (id) {
        this.controller.update.loading = true;

        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
        };

        console.log('flashmobs.payment...', data);

        this.$api.COM
          .patch('/flashmob/'+id+'/payment', data)
          .then(response => {
            console.log('flashmobs.payment => ', response);

            this.toggleToast(
              true,
              'Pagamento salvo para os parceiros do Flashmob',
              5000,
              false
            );

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      cancel (id, fields) {
        this.controller.update.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
        };

        console.log('flashmobs.delete...');

        this.$api.COM
          .delete('/flashmob/'+id, { data })
          .then(response => {
            console.log('flashmobs.delete => ', response);
            this.select(null);

            this.$nextTick(() => {
              this.$delete(this.items, id);
              this.toggleToast(
                true,
                `Flashmob ${fields.title} apagado com sucesso`,
                5000,
                false
              );
            })

            this.controller.list.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(error, 'Erro desconhecido. Vamos investigar o que houve! 😊', true);
              // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              // this.view.loader = setTimeout(($) => {
              //   this.view.loader = null;
              //   $.getBenefits(script);
              // }, 7000, this, script);
            }
          })
          .finally(() => {
            this.controller.update.loading = false;
            this.controller.update.files = null;
          });
      },

      openMap (buzzers) {
        this.$router.push('/buzzers?filter='+_.join(buzzers, ','));
      },

      getDriverList (params, loader, callback) {
        if (params.status=='ACT') _.unset(params, 'qtd');
        console.log('get list for ', params);
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        const query = _.reduce(params, (s,v,k) => {
          return s + (_.isNil(v) ? '' : '&'+k+'='+v);
        },'');
        
        this.$api.COM({
          url: '/searchprofiledriverextadmin/'+username+'/'+token,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: params,
        })
        .then(response => {
          console.log('getDriverList => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              const drivers = this.processDrivers(response.data.dados);
              this.cacheDrivers(drivers, this.drivers);
            }

          }else if(response.data.retorno==404){
            // no results
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, 'Sua sessão expirou...');
          }else{
            this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },

      openDriver (id) {
        setTimeout(() => {
          this.$router.push({ path: `/drivers/${id}` });
        }, 250);
      },

      toggleMessenger (b, to, subject=null, message=null, reference=null) {
        console.log('messenger:', b, to, reference);
        this.controller.messenger.toggle = b;
        this.controller.messenger.to = to;
        this.controller.messenger.subject = subject;
        this.controller.messenger.message = message;
        this.controller.messenger.reference = reference;
      },
      
    },

    mounted () {
      this.get();
      this.getDriverList({ status: 'ACT' }, this.controller.update);
    },

    components: {
      Flashmob: () => import('@/views/flashmobs/Flashmob'),
      Messenger: () => import('@/components/Messenger'),
      // MBtnLong: () => import('@/components/mBtnLong'),
    }
  }
</script>